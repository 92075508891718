body {
  position: relative;
}

.header_outer {
  position: absolute;
  z-index: 100;
  height: 90px;
  width: 100%;
  display: flex;
  background: rgba(255, 255, 255);
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-bottom: 0.5px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    to right,
    rgba(242, 103, 34, 1),
    rgba(0, 191, 242, 1)
  );
  border-color: linear-gradient(rgba(242, 103, 34, 1), rgba(0, 191, 242, 1));
  position: sticky;
}

.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
  background-color: #222222;
}

.img_outer {
  height: auto;
  width: 13.264vw;
  position: absolute;
  top: 24px;
  left: 6%;
  transition: height 1s ease-in-out;
}

.img_outer1 {
  display: flex;
  height: 40px;
  width: 15vw;
  position: absolute;
  top: 25px;
  transition: height 1s ease-in-out;
  left: 80%;
}

.img_logo {
  transition: height 1s ease-in-out;
}

.link_outer {
  display: flex;
  height: fit-content;
  width: 24.625rem;
  margin-left: 0%;
  font-family: Georgia, "Times New Roman", Times, serif;
  justify-content: space-between;
  align-items: center;
}

.link_outer > .link {
  padding: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
}

.link:hover {
  color: #222;
}

.menu_icon {
  display: none;
  transition: all 1s ease-in-out;
  height: 20px;
  width: 20px;
}

.menu_list {
  display: block;
  transition: all 1s ease-in;
}

.hide {
  display: none;
  transition: all 1s ease-in;
}

.act {
  color: #222222;
  font-size: 1.125rem;
  font-weight: 600;

  & .dot {
    visibility: visible;
  }
}

.def {
  font-size: 1.125rem;
  color: #666666;
  font-weight: 400;

  & .dot {
    visibility: hidden;
  }
}

.uparrow {
  height: 56px;
  width: 56px;
  position: absolute;
  z-index: 100;
  top: 800px;
  left: calc(94%);
  cursor: pointer;
}

.trynow {
  font-size: 1.125em;
  color: #f26722;
  font-family: Georgia, "Times New Roman", Times, serif;
  position: relative;
  left: calc(5%);
  bottom: 3px;
}

@media (max-width: 900px) {
  .mobile_outer {
    font-size: 16px;
  }

  .header_outer {
    position: absolute;
    z-index: 100;
    height: 84px;
    width: 100%;
    display: flex;
    background: rgba(255, 255, 255);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom: 0.5px solid;
    border-image-slice: 1;
    border-image-source: linear-gradient(
      to right,
      rgba(242, 103, 34, 1),
      rgba(0, 191, 242, 1)
    );
    border-color: linear-gradient(rgba(242, 103, 34, 1), rgba(0, 191, 242, 1));
    position: sticky;
  }

  .menu_icon {
    display: flex;
    left: 88%;
    top: 24px;
    height: 24px;
    width: 24px;
    position: absolute;
    color: #222222;
    z-index: 100;
    /* font-size: 24px; */
  }

  .link_outer {
    display: none;
  }

  .img_outer {
    height: 28px;
    width: 121px;
    transition: all 1s ease-in-out;
  }

  .menu_list {
    width: 100%;
    height: fit-content;
    position: absolute;
    top: 55px;
    background-color: #ffffff;
    z-index: 100;
  }

  .list {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    font-size: 1.125em;
    font-family: Georgia, "Times New Roman", Times, serif;
    color: #666666;
  }

  .list_items {
    margin-bottom: 24px;
    margin-left: 24px;
  }

  .img_mobilelogo {
    width: 140px;
    height: 20px;
    margin: 0 24px 24px 24px;
  }

  .uparrow {
    display: none;
  }

  .trynow {
    font-size: 1em;
    color: #f26722;
    font-family: Georgia, "Times New Roman", Times, serif;
    position: relative;
    left: calc(3%);
    bottom: 12px;
    width: 100%;
  }
}

@media (max-width: 400px) {
  .mobile_outer {
    font-size: 14px;
  }

  .menu_icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .trynow {
    font-size: 1em;
    color: #f26722;
    font-family: Georgia, "Times New Roman", Times, serif;
    width: 100%;
    position: relative;
    left: calc(9%);
    bottom: 12px;
  }
}
