.advantageouter{
    height: fit-content;
    width: 100%;
    margin-bottom: 56px;
    
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.content > img{
    height: auto;
    width: 12.083vw;
    margin-top: 56px;
    margin-left: 85%;
}
.maincontent{
    display: flex;
    justify-content: space-evenly;
    margin-top: 64px;
    gap: 25px;
}
.advantageimg{
    height: auto;
    width: 42.708vw;
    
}

.advantagelist{
    display: flex;
    flex-direction: column;
}

.advpara{
    width: 44.444vw;
    font-family: Work Sans;
    line-height: 2rem;
    font-size: 1.25rem;
    padding-left: 107px;
}
.list{
    height: fit-content;
    width: 44.444vw;
    margin-top: 40px;
    font-family: Work Sans;
    font-size: 1.188rem;
    padding-left: 107px;
}
.list > ul > li{
    list-style-image: url('../../../public/images/Redesignpics/listdot.svg');
    padding-left: 16px;
}

.list > ul > li:not(:first-child){
    margin-top: 16px;
}

@media (max-width: 600px){

    .maincontent{
        flex-direction: column-reverse;
        align-items: center;
    }
    .advantagelist{
        margin-top: 32px;
        
    }
    .maincontent{
        margin-top: 0;
    }
    .list{
        width: 90%;
        padding-left: 16px;
        font-size: 1rem;
        align-items: flex-start;
    }
    .advantageimg{
        width: 90%;
        margin-top: 32px;
    }
    .list > ul > li{
        list-style-image: url('../../../public/images/Redesignpics/listdot.svg');
        padding-left: 12px;
    }
}

@media (min-width:600px) and (max-width: 900px){
    html{
        font-size: 14px;
    }
    .maincontent{
        margin-top: 0;
        flex-direction: row-reverse;
        align-items: center;
    }
    .list{
        padding-left: 16px;
    }
    .advantagelist{
        margin-top: 0;
    }
}