.faq_outer{
    height: fit-content;
    width: 100%;
}

.faq_inner{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 120px;
}

.faq_poster{
    height: fit-content;
    width: 100%;
}

.faq_poster > img{
    height: 100%;
    width: 100%;
}

.faq_section{
    height: fit-content;
    width: 100%;
    margin-top: 82px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.questionbox{
    width: 90%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    margin-top: 88px;
}
.image_box{
    width: 36.806vw;
    height: auto;
}
.image_box>img{
    width: 36.806vw;
    height: auto;
}
.questions{
    height: auto;
    width: 42.222vw;
    overflow-y: scroll;
    overflow-x: hidden;
    scroll-behavior: smooth;
}
::-webkit-scrollbar{
    width: 7px;
    height: 10px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
 }
 ::-webkit-scrollbar-thumb {
    background: #6b6a6a;
 }
 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* .questions {
    -ms-overflow-style:initial; 
    scrollbar-width: thin;
}
.questions::-webkit-scrollbar { 
    display: block;  
} */

.question_section{
    height: fit-content;
    width: 42.222vw;
}

.enableclick{
    height: fit-content;
    width: fit-content;
}
.question_section{
    height: 538px;
}
.question_outer{
    height: 70px;
    width: 42.222vw;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    border-radius: 4px 4px 0 0 ;
    background: #FFF;
    /* box-shadow: 0px 2px 16px 0px rgba(174, 170, 168, 0.32); */
    padding: 15px;
    margin-top: 24px;
    margin-bottom: 10px;
    cursor: pointer;
}

.question_outer > p{
    font-family: Work Sans;
    font-size: 1.125rem;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    color: #222222;
    width:38.889vw;
    padding-right: 24px;
    padding-bottom: 10px;
}

.button_outer{
    height: 24px;
    width: 24px;
    border-radius: 50%;
    /* border: 1px solid #7a7877; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.button_outer > img{
    height: 16px;
    width: 16px;
}

.answer_outer{
    height: fit-content;
    width: 42.222vw;
    border-radius: 0px 0px 4px 4px;
    background: #FFFF;
    padding: 0 24px 24px 15px;
}

.whole_outer{
    border-bottom: 0.5px solid #e0dedc;
}

.answer_outer > p{
    font-size: 1rem;
    font-family: Work Sans;
    font-weight: 400;
    color: #222222;
}

.show{
    display: block;
}

.hide{
    display: none;
}




@media screen and (max-width: 600px){
   
    .faq_outer{
        position: relative;
    }
    .faq_section{
        margin-top: 0;
    }
    .questionbox{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 0;
    }
    .image_box{
        width: 100%;
        height: 120px;
        position: relative;
    }
    .image_box > img{
        width: 100%;
        height: 120px;
        object-fit: cover;
    }
    .faq_heading{
        position: absolute;
        top: 92px;
        width: 82.5vw;
        height: 55px;
        border-radius: 80px;
        background-color: #FFF;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .questions{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 32px;
    }
    .question_section{
        height: fit-content;
        width: 86.669vw;
    }
    .question_outer{
        width: 86.669vw;
        height: fit-content;
    }
    .question_outer > p{
        height: fit-content;
        width: 76.667vw;
        padding-right: 16px;
    }
    .answer_outer > p{
        width: 80.667vw;
    }
}

@media (min-width: 600px) and (max-width: 900px){
    .faq_outer{
        position: relative;
    }
    .faq_section{
        margin-top: 0;
    }
    .questionbox{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: 0;
    }
    .image_box{
        width: 100%;
        height: 199px;
        position: relative;
    }
    .image_box > img{
        width: 100%;
        height: 199px;
        object-fit: cover;
    }
    .faq_heading{
        position: absolute;
        top: 167px;
        width: 82.5vw;
        height: 55px;
        border-radius: 80px;
        background-color: #FFF;
        z-index: 1;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .questions{
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 32px;
    }
    .question_section{
        height: fit-content;
        width: 86.669vw;
    }
    .question_outer{
        width: 86.669vw;
        height: fit-content;
    }
    .question_outer > p{
        height: fit-content;
        width: 76.667vw;
        padding-right: 16px;
    }
    .answer_outer > p{
        width: 80.667vw;
    }
}
