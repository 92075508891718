html {
    font-size: 16px;
}

.advantageouter {
    height: fit-content;
    width: 100%;
    margin-bottom: 56px;

}

.content {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.content>img {
    height: auto;
    width: 12.083vw;
    margin-top: 56px;
    margin-left: 57%;
}

.maincontent {
    display: flex;
    justify-content: space-between;
    margin-top: 64px;
}

.advantageimg {
    height: auto;
    width: 37.292vw;
}

.advantagelist {
    display: flex;
    flex-direction: column;

}

.advpara {
    width: 44.444vw;
    font-family: Work Sans;
    line-height: 1.875rem;
    font-size: 1.25rem;
    text-align: justify;
}

.list {
    height: fit-content;
    width: 44.444vw;
    margin-top: 40px;
    font-family: Work Sans;
    font-size: 1.188rem;
}

.list>ul>li {
    list-style-image: url('../../../public/images/Redesignpics/listdot.svg');
    padding-left: 16px;
}

.list>ul>li:not(:first-child) {
    margin-top: 24px;
}

@media (max-width: 900px) {

    .maincontent {
        flex-direction: column;
        width: 100%;
    }

    .advantageimg {
        width: 91.111vw;
    }

    .advantagelist {
        width: 100%;
    }

    .advpara {
        width: 100%;
        margin-top: 32px;
        text-align: justify;
        font-size: 1.1rem;
    }

}