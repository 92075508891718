.citation {
    border-radius: 0px;
    border-bottom: solid 1px blue;
    position: absolute;
}

.reference {
    border-radius: 0px;
    border-bottom: solid 1px green;
    position: absolute;
}

.popup_button {
    position: fixed !important;
    display: none;
    pointer-events: none;
    width: 1px !important;
    height: 10px !important;
    padding: 0 !important;
    left: -100px;
    top: -100px;
}


.added_marker {
    position: absolute;
    background-color: greenyellow;
    opacity: 0.5;
}

.accepted_text {
    color: #000 !important;
    opacity: 0.5;
}

.deleted_marker {
    position: absolute;
    background-color: red;
    width: 5px !important;
}

.inline_equation {
    border-radius: 0px;
    border-bottom: solid 1px orange;
    position: absolute;
}

.equation {
    border-radius: 0px;
    border-bottom: solid 1px green;
    position: absolute;
}

.equ_cit {
    border-radius: 0px;
    border-bottom: solid 1px rgb(113, 0, 128);
    position: absolute;
}

.table_cit {
    border-radius: 0px;
    border-bottom: solid 1px rgb(241, 95, 50);
    position: absolute;
}