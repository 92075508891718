.about2_outer{
    height: fit-content;
    width: 95%;
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.content1{
    height: fit-content;
    width: 100%;
}

.contentpara{
    height: fit-content;
    width: 57.639vw;
    margin-top: 24px;
    line-height: 32px;
    font-size: 1.125rem;
    font-family: Work Sans;
}

.contenthighlight{
    height: fit-content;
    width: 59.028vw;
    /* padding: 43px 137px; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 16px;
    background: #F0F4F6;
    box-shadow: 0px 0px 0px 0px rgba(34, 34, 34, 0.10), 0px 2px 4px 0px rgba(34, 34, 34, 0.10), -1px 8px 8px 0px rgba(34, 34, 34, 0.09), -1px 18px 11px 0px rgba(34, 34, 34, 0.05), -2px 32px 13px 0px rgba(34, 34, 34, 0.01), -4px 49px 14px 0px rgba(34, 34, 34, 0.00);
    margin-top: 66px;
    font-size: 1.625rem;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: 400;
    position: relative;
    margin-bottom: 271px;
}

.contenthighlight > p{
    padding: 43px 137px;
}
.contenthighlight > img{
    position: absolute;
    top: -20px;
    left: 84px;
}

.imgbig{
    height: auto;
    width: 34.722vw;
    position: absolute;
    top: calc(100% - 550px);
    left:70%;
    z-index: -1;
}

.imgsmall{
    height: auto;
    width: 19.444vw;
    position: absolute;
    top: 35%;
    left: -21%;
    z-index: -1;
}

@media (max-width: 600px){
    
    .about2_outer{
        margin-top: 64px;
    }
    .content1{
        width: 91.111vw;
    }
    .contentpara{
        width: 100%;
        text-align: justify;
        line-height: 23px;
        font-size: 1rem;
    }
    .contenthighlight{
        width: 60vw;
        height: 128px;
        font-size: 1rem;
        margin-bottom: 112px;
        margin-top: 63px;
    }
    .contenthighlight > p{
        padding: 20px 16px;
    }
    .contenthighlight > img{
        height: 16px;
        width: 13px;
        position: absolute;
        top: -10px;
        left: 24px;
    }
    .imgsmall{
        height: auto;
        width: 26.667vw;
        top: 60%;
        left: -30%;
    }
    .imgbig{
        height: auto;
        width: 33.333vw;
        top: -50%
    }
}

@media (min-width: 600px) and (max-width: 900px){

    .about2_outer{
        margin-top: 64px;
    }
    .contentpara{
        width: 100%;
        text-align: justify;
        line-height: 26px;
    }
    .contenthighlight{
        width: 63.286vw;
        height: 140px;
        font-size: 1rem;
        margin-bottom: 132px;
        margin-top: 133px;
    }
    .contenthighlight > p{
        padding: 28px 48px;
        line-height: 26px;
        font-size: 1.125rem;
    }
    .contenthighlight > img{
        height: 26px;
        width: 32px;
        position: absolute;
        top: -15px;
        left: 48px;
    }
    .imgsmall{
        height: auto;
        width: 21.429vw;
        top: 60%;
        left: -29%;
    }
    .imgbig{
        height: auto;
        width: 35.714vw;
        top: -110%;
        left: 73%;
    }
}