
.inputtag{
   display: none;
}

.labeltag{
    height: 32px;
    width: 100px;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
    background-color: #edf2f7;
    color: #1a202c;
    font-size: 14px;
    font-weight: 500;
}


.filelist{
    padding: 2px;
    cursor: pointer;
}

.delicon{
    padding-right: 5px;
    cursor: pointer;
}