.advantageouter{
    height: fit-content;
    width: 100%;
    margin-bottom: 56px;
    
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.content > img{
    height: auto;
    width: 12.083vw;
    margin-top: 56px;
}
.maincontent{
    display: flex;
    justify-content: space-evenly;
    margin-top: 64px;
}
.advantageimg{
    height: auto;
    width: 42.708vw;
}

.advantagelist{
    display: flex;
    flex-direction: column;
    
}

.advpara{
    width: 44.444vw;
    font-family: Work Sans;
    line-height: 1.75rem;
    font-size: 1.25rem;
}
.list{
    height: fit-content;
    width: 44.444vw;
    margin-top: 24px;
    font-family: Work Sans;
    font-size: 1.125rem;
}
.list > ul > li{
    list-style-image: url('../../../public/images/Redesignpics/listdot.svg');
    padding-left: 16px;
}

.list > ul > li:not(:first-child){
    margin-top: 16px;
}

@media (max-width: 600px){
   
    .maincontent{
        width: 100%;
        margin-top: 32px;
    }
    .advpara{
        text-align: justify;
        text-justify: distribute;
        width: 100%;
    }
    .advpara > p{
        font-size: 1rem;
    }
    .advantageimg{
        margin-top: 32px;
        width: 100%;
    }
    .list{
        width: 100%;
        margin-left: 16px;
        font-size: 1rem;
    }
    .list > ul > li{
        padding-left: 12px;
    }
}

@media (min-width: 600px) and (max-width: 900px){
    .advpara{
        width: 100%;
        text-align: center;
    }
    .advantageimg{
        width: 80%;
        margin: 32px 10%;

    }
    .list{
        width: 100%;
        margin-left: 16px;
    }
}