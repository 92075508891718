.footer_outer {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-top: 0.5px solid #B9B2AF;
    background-color: rgba(240, 246, 248, 48);
}

.leftsection {
    height: fit-content;
    width: 38.403vw;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    margin-left: 70px;
    align-items: center;
}

.logo {
    height: auto;
    width: 12.708vw;
}

.logocircle {
    height: 42px;
    width: 42px;
    border-radius: 50%;
}

.logocircle:hover {
    border: 1px solid black;
}

.wordlink {
    width: 22.917vw;
    display: flex;
    justify-content: space-between;
    font-size: 1.125rem;
    color: #222;
    font-family: Work Sans;
}

.wordlink:hover {
    color: #000;
}

.rightsection {
    height: fit-content;
    width: 16.667vw;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    margin-right: 70px;
}

.sociallogo {
    display: flex;
    justify-content: flex-end;
    gap: 2.083vw;
}

.sociallogo>img {
    height: 42px;
    width: 42px;
}

.trademark {
    font-family: Work Sans;
    font-size: 1.125rem;
    color: #222;
    text-align: right;
    margin-top: 23.5px;
    margin-bottom: 32px;
}

@media screen and (max-width: 600px) {
    html {
        font-size: 14px;
    }

    .footer_outer {
        height: fit-content;
        flex-direction: column;
    }

    .leftsection {
        height: fit-content;
        width: 39.722vw;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 24px;
        margin-left: 16px;
        align-items: center;
    }

    .wordlink {
        width: 39.722vw;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        font-size: 1rem;
        color: #222;
        font-family: Work Sans;
        margin-top: 24px;
        gap: 16px;
    }

    .wordlink>p:nth-child(1) {
        margin-bottom: 16px;
    }

    .logo {
        height: auto;
        width: 38vw;
    }

    .logo>img {
        width: 38vw;
    }

    .rightsection {
        flex-direction: column;
        width: 95%;
        margin-left: 16px;
        margin-top: 24px;
    }

    .sociallogo {
        display: flex;
        justify-content: flex-start;
        width: 35%;
    }

    .logoimg {
        height: 32px;
        width: 32px;
    }

    .logocircle {
        height: 32px;
        width: 32px;
    }
}


@media (min-width: 600px) and (max-width: 900px) {
    .leftsection {
        height: 54px;
        width: 51.600vw;
        margin-left: 32px;
    }

    .logo {
        height: 40px;
        width: 24.400vw;
    }

    .wordlink {
        display: flex;
        flex-direction: column;
    }

    .wordlink>p:not(:first-child) {
        margin-top: 16px;
    }

    .sociallogo {
        width: 23.467vw;
    }

    .logoimg {
        height: 32px;
        width: 32px;
        margin-right: 16px;
    }

    .rightsection {
        width: fit-content;
        margin-right: 32px;
    }

    .logocircle {
        height: 32px;
        width: 32px;
    }
}