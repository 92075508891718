
.whoweare_outer{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: rgba(240, 246, 248, 48%); */
    z-index: 0;
}

.who_heading{
    width: 60vw;
    height: 45px;
    margin-top: 56px;
    text-align: center;
}

.who_para{
    max-height: fit-content;
    max-width: 90.278vw;
    margin-top: 24px;
    margin-bottom: 60px;
}

.who_para > p{
    height: fit-content;
    line-height: 27px;
    text-align: center;
    font-size: 1.188rem;
    font-family: Work Sans;
}

.who_img{
    height: 120px;
    width: 840px;
    display: flex;
    justify-content: space-evenly;
    margin-top: 56px;
}

.who_logo{
    width: 120px;
    height: 120px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* box-shadow: 0px 44px 17px rgba(185, 179, 177, 0.01), 0px 24px 15px rgba(185, 179, 177, 0.05), 0px 11px 11px rgba(185, 179, 177, 0.09), 0px 3px 6px rgba(185, 179, 177, 0.1), 0px 0px 0px rgba(185, 179, 177, 0.1); */
}

.cmp_logo{
    width: 79px;
    height: 45px;
}

@media (max-width: 600px){
    /* html{
        font-size: 16px;
    } */
    .whoweare_outer{
        height: fit-content;
       /* / margin-bottom: 20px; */
    }

    .who_heading {
        width: 90vw;
        text-align: center;
        margin-top: 32px;
    }

    .whowe_heading{
        font-size: 18px;
    }
    .who_para{
        margin-top: 5px;
    }

    .who_para > p{
        font-size: 1rem;
        font-family: Work Sans;
        color: #666666;
        line-height: 24px;
        text-align: justify;
    }
}

@media (min-width: 600px) and (max-width: 900px){
    .whoweare_outer{
        height: fit-content;
        margin-bottom: 20px;
    }

    .who_heading{
        width: 70vw;
        text-align: center;
    }

    .who_para{
        font-size: 14px;
        line-height: 24px;
    }

    .who_img{
        height: 100px;
        width: 75vw;
        margin-bottom: 20px;
    }

    .who_logo{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        /* box-shadow: 0px 44px 17px rgba(185, 179, 177, 0.01), 0px 24px 15px rgba(185, 179, 177, 0.05), 0px 11px 11px rgba(185, 179, 177, 0.09), 0px 3px 6px rgba(185, 179, 177, 0.1), 0px 0px 0px rgba(185, 179, 177, 0.1); */
}
}