.feature_outer {
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.feature_content {
    height: fit-content;
    width: 100%;
    display: flex;
    /* margin-top: 120px; */
    display: flex;
    justify-content: center;
    background-image: url("../../../public/images/Redesignpics/featurebg.svg");
    object-fit: cover;

}

/* .feature_content > img{
    height: auto;
    width: 15.347vw;
} */

.content {
    display: flex;
    height: fit-content;
    width: 90%;
    flex-direction: column;
    /* align-items: center; */
    justify-content: space-around;
}

.content>.heading {
    margin: 48px auto 40px auto;
}

.feature_media {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.feature_video {
    height: auto;
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
}

.feature_video>video {
    height: auto;
    width: 100%;
    border-radius: 16px;
    border: 4px solid #dfdbda;
    /* border: 4px solid #666;  */
}

.playbtn {
    background-image: url("../../../public/images/Redesignpics/stop.svg");
    background-repeat: no-repeat;
    width: 64px;
    height: 64px;
    position: absolute;
    left: -18%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin: auto;
    background-size: contain;
    background-position: center;
    z-index: 2;
}

.playbtn:hover {
    background-image: url("../../../public/images/Redesignpics/play.svg");
    background-repeat: no-repeat;
    width: 64px;
    height: 64px;
    position: absolute;
    left: -18%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    margin: auto;
    background-size: contain;
    background-position: center;
    z-index: 2;
}

.feature_para {
    width: 40%;
}

.feature_para>p {
    text-align: justify;
}

.feature_para {
    display: flex;
    justify-content: center;
    align-items: center;
}

.feature_para>p {
    font-size: 1.125rem;
    font-family: Work Sans;
    line-height: 1.75rem;
    text-align: left;
    padding-left: 20px;

}

.features_layout {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    align-items: center;
    margin-top: 40px;

}

.heading1 {
    height: 58px;
    width: 25%;
    background-color: #222222;
    color: #ffffff;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.75rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
}

.pos1 {
    position: relative;
    left: -37.5%;
}

.pos2 {
    position: relative;
    /* top: 30.3%; */
    left: -12.5%;
}

.pos3 {
    position: relative;
    top: 63%;
    left: 12.5%;
}

.pos4 {
    position: relative;
    left: 37.5%;
}

.feature1 {
    width: 90%;
    /* margin-top: 58px; */
    height: fit-content;
    display: flex;
    justify-content: center;

}

@media (min-width: 600px) and (max-width: 900px) {
    .heading1 {
        height: 50px;
        width: 100%;
    }

    .pos1,
    .pos2,
    .pos3,
    .pos4 {
        left: 0;
    }

    .feature_media {
        flex-direction: column;
    }

    .feature_video {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .video {
        height: 220px;
        width: 360px;
        border-radius: 16px;
    }

    .feature_para {
        width: 100%;
        margin-top: 32px;
    }

    .feature_para>p {
        text-align: center;
    }

    .feature_content {
        margin-top: 24px;
    }

    .content {
        width: 90%;
    }

    .playbtn {
        background-image: url("../../../public/images/Redesignpics/stop.svg");
        background-repeat: no-repeat;
        width: 56px;
        height: 56px;
        position: absolute;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;
        margin: auto;
        background-size: contain;
        background-position: center;
        z-index: 2;
    }

    .playbtn:hover {
        background-image: url("../../../public/images/Redesignpics/play.svg");
        background-repeat: no-repeat;
        width: 56px;
        height: 56px;
        position: absolute;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;
        margin: auto;
        background-size: contain;
        background-position: center;
        z-index: 2;
    }

    .playbtn:hover {
        background-image: url("../../../public/images/Redesignpics/play.svg");
        background-repeat: no-repeat;
        width: 64px;
        height: 64px;
        position: absolute;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;
        margin: auto;
        background-size: contain;
        background-position: center;
        z-index: 2;
    }
}

@media (max-width: 600px) {
    .heading1 {
        height: 50px;
        width: 100%;
    }

    .pos1,
    .pos2,
    .pos3,
    .pos4 {
        left: 0;
    }

    .feature_media {
        flex-direction: column;
    }

    .feature_video {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .video {
        height: 160px;
        width: 264px;
        border-radius: 16px;
    }

    .feature_para {
        width: 100%;
        margin-top: 32px;
    }

    .feature_para>p {
        text-align: justify;
        font-size: 1rem;
        padding-left: 0;
    }

    .feature_content {
        margin-top: 24px;
    }

    .feature_video {
        height: fit-content;
    }

    .content {
        width: 90%;
    }

    .content>.heading {
        margin: 24px auto 32px auto;
    }

    .playbtn {
        background-image: url("../../../public/images/Redesignpics/stop.svg");
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        position: absolute;
        left: 0;
        right: 0%;
        top: 0%;
        bottom: 0%;
        margin: auto;
        background-size: contain;
        background-position: center;
        z-index: 2;
    }

    .playbtn:hover {
        background-image: url("../../../public/images/Redesignpics/play.svg");
        background-repeat: no-repeat;
        width: 40px;
        height: 40px;
        position: absolute;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;
        margin: auto;
        background-size: contain;
        background-position: center;
        z-index: 2;
    }
}