.right_bar_container {
  width: 1.75rem;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.right_bar_menu {
  writing-mode: tb;
  transform: rotate(180deg);
  cursor: pointer;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  padding: 8px 4px 8px 4px;
  border-radius: 0px 4px 4px 0;
}

.right_bar_menu:hover {
  background-color: #222;
}

.current {
  background-color: #222;
  color: #fff;
}
.disabled {
  background-color: #8b8b8b;
  color: #e0dbd8;
}
