.loading {
  height: 100%;
  width: 100%;
  background-color: aliceblue;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  justify-content: center;
  display: grid;
  align-content: center;
}
.hide {
  display: none;
}