.outer_container {
    width: 100%;
    height: fit-content;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-size: 16px;
}

.main_header {
    height: 90px;
    width: 100vw;
    top: 0;
    z-index: 10;
    position: sticky;
}


.main_poster {
    width: 100%;
    height: fit-content;
}

.about_container {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
}

.service_container {
    height: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 0;
}

.who_we_are {
    height: fit-content;
    width: 100%;
}

.connect {
    height: fit-content;
    width: 100%;
}

.contact {
    height: fit-content;
    width: 100%;
}

.footer {
    height: fit-content;
    width: 100%;
}


@media (min-width: 600px) and (max-width: 900px) {
    .about_container {
        height: fit-content;
        width: 100%;
    }

    .service_container {
        height: fit-content;
        width: 100%;
    }

    .main_header {
        height: fit-content;
        width: 100%;
    }

    .uparrow {
        display: none;
    }

    .overlay {
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        cursor: pointer;
    }
}

@media (max-width: 600px) {

    .outer_container {
        width: 100%;
        height: fit-content;
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow-x: hidden !important;
    }

    .main_header {
        height: fit-content;
        position: fixed;
    }

    .main_poster {
        width: 100%;
        height: fit-content;
        padding-top: 72px;
    }

    .about_container {
        height: fit-content;
        width: 100%;
    }

    .about_container {
        height: fit-content;
        width: 100%;
    }

    .service_container {
        height: fit-content;
        width: 100%;
        display: flex;
        justify-content: center;
        z-index: 0;
    }

    .who_we_are {
        height: fit-content;
        width: 100%;
    }

    .connect {
        height: fit-content;
        width: 100%;
    }

    .contact {
        height: fit-content;
        width: 100%;
    }

    .footer {
        height: fit-content;
        width: 100%;
    }

    .overlay {
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 2;
        cursor: pointer;
    }
}