.connect_outer{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.connect_heading{
    height: 32px;
    width: 391px;
    margin-top: 104px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

/* .connect_para{
    height: 32px;
    width: 60vw;
    font-size: 1.125rem;
    font-family: Roboto, sans-serif;
    font-weight: 400;
    margin-top: 16px;
    color: #666666;
    text-align: center;
} */

.connect_details{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
}
.connect_list{
    height: fit-content;
    width: 90%;
    text-align: center;
    margin-top: 64px;
    display: flex;
    justify-content: space-between;
}
.connectlist1{
    height: 500px;
    width: 27.785vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    background: #FFF;
    box-shadow: 0px 0.5205860137939453px 3.7819926738739014px 0px rgba(210, 205, 202, 0.09), 0px 1.6443583965301514px 8.955440521240234px 0px rgba(210, 205, 202, 0.10), 0px 3.8529300689697266px 17.18541717529297px 0px rgba(210, 205, 202, 0.11), 0px 8.4129056930542px 33.464874267578125px 0px rgba(210, 205, 202, 0.12), 0px 22px 87px 0px rgba(210, 205, 202, 0.19);
}
.heading{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 2.125rem;
    font-weight: 400;
    margin-top: 24px;
}
.break{
    height: 5px;
    width: 100%;
    margin-top: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #222;
}
.break > hr{
    color: #222;
    width: 8.333vw;
}
.address{
    margin-top: 16px;
    width: 25.556vw;
}
.address > p{
    font-family: Work Sans;
    font-size: 1.125rem;
    text-align: left;
}
.phone{
    margin-top: 24px;
    width: 25.556vw;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: left;
}
.phone3{
    margin-top: 16px;
    width: 25.556vw;
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: left;
}
.mail{
    margin-top: 8px;
    width: 25.556vw;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: left;
    overflow-wrap: break-word;
}
.mail3{
    margin-top: 8px;
    width: 25.556vw;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: left;
    margin-bottom: 146px;
}
.mailimg{
    height: 20px;
    width: 20px;
}
.delivery{
    margin-top: 24px;
    width: 25.556vw;;
    display: flex;
    flex-direction: column;
    justify-content: left;
}
.delivery > .headingdelivery{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.25rem;
    font-weight: 400;
    text-align: left;
}
.breakdelivery{
    height: 5px;
    width: 100%;
    margin-top: 4px;
    display: flex;
    align-items: center;
}
.breakdelivery > hr{
    width: 10%;
    color: #666;    
}
.deliveryadd{
    margin-top: 16px;
    margin-bottom: 42px;
}

.mailaddress,.mailaddress1 > .mail{
    font-family: Work Sans;
    font-size: 1.125rem;
    color: #222;
    text-align: left;
    display: flex ;
    width: 25.556vw;
}
.usmail,.indmail,.germail{
    font-family: Work Sans;
    font-size: 1.125rem;
    color: #222;
    text-align: left;
}

.mailaddress1,.mailaddress{
    width: 25.556vw;;
    display: flex;
    flex-direction: column;
    justify-content: left;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 1.25rem;
    color: #222;
    text-align: left;
    margin-top:16px;
}
.mailaddress1{
    margin-bottom: 24px;
}

.delivery > p{
    font-family: Work Sans;
    font-size: 1.125rem;
    color: #222;
    text-align: left;
}

.form{
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
}

@media (max-width: 600px) {
    .connect_heading{
        margin-top: 64px;
    }
    .connect_list{
        display: flex;
        flex-direction: column;
    }
    .connectlist1{    
        height: 400px;
        width: 91.111vw;
    }
    .connectlist1:not(:first-child){
        margin-top: 40px;
    }
    .address{
        width: 82.222vw;
    }
    .address > p{
        font-size: 1rem;
    }
    .phone{
        width: 82.222vw;
    }
    .phone > p{
        font-size: 1rem;
    }
    .mail{
        width: 82.222vw;
    }
    .mail > p{
        font-size: 1rem;
    }
    .delivery{
        width: 82.222vw;
    }
    .deliveryadd{
        font-size: 1rem;
    }
    .mailaddress,.mailaddress1{
        width: 82.222vw;
    }
    .mailaddress,.mailaddress1 > p{
        font-size: 1rem;
    }
    .phone3, .mail3{
        width: 82.222vw;
    }
    .phone3, .mail3 > p{
        font-size: 1rem;
    }
    .usmail,.indmail,.germail{
        font-family: Work Sans;
        font-size: 1.125rem;
        color: #222;
        text-align: left;
    }
}

@media (min-width: 600px) and (max-width: 900px){
    
    .connect_heading{
        margin-top: 80px;
    }
    .connect_list{
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content:center ;
        gap: 24px;
        
    }
    .connectlist1{
        width: 43.733vw;
        height: 425px;
    }
    .address{
        width: 39.467vw;
    }
    .phone{
        width: 39.467vw;
    }
    .mail{
        width: 39.467vw;
       
    }
    .delivery{
        width: 39.467vw;
    }
    .mailaddress1{
    }
    .mailaddress,.mailaddress1{
        width: 39.467vw;
    }
    .phone3, .mail3{
        width: 39.467vw;
    }
}