.outer{
    position: relative;
    height: 300px;
    width: 100%;
    background-color: #222222;
}

.inner{
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading > p{
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 2em;
    font-weight: 400;
    color: #ffffff;
    margin-top: 40px;
}

/* .carousel_outer{
    
    height: 120px;
    width: 90.27vw;
    margin-top: 56px;
    margin-bottom: 44px;
    display: flex;
    gap: 40px;
    justify-content: center;
    z-index: -0;
} */
.carousel_out{
    /* position: relative; */
    height: 120px;
    width:  82%;
    margin-top: 56px;
    margin-bottom: 44px;
    display: flex;
    justify-content: left;
    z-index: 0;
    overflow: hidden;
}
/* .logo_outer{
    height: 120px;
    width: 16.667vw;
    border: 1px solid #fff;
    border-radius: 12px;
    background-color: #222222;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
} */
.logoouter{
    height: 120px;
    width: 240px;
    border: 1px solid #fff;
    border-radius: 12px;
    background-color: #222222;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: all 1s ease;
}

.logoouter > img{
    background-color: #222222;
    height: 98px;
    width: 100%;
    object-fit: contain;
    padding: 6px;
}

/* .carousel{
    height: 120px;
    width: 80%;
    position: absolute;
    z-index: -1;
    display: flex;
    flex-direction: row;
} */
.npbtn{
    position: absolute;
    height: 56px;
    width: 56px; 
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn1pos{
    top: 170px;
    left: calc(3%);
}
.btn2pos{
    top: 170px;
    right: calc(3%);
}
.absolute{
   display: block;
}
.hidden{
    display: block;
}
.slider_outer{
    width: max-content;
    display: flex;
    gap: 40px;
    box-sizing: border-box;
    transition: transform 0.3s ease;
}

@media (min-width:400px) and (max-width: 600px){
    
    .carousel_out{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center ;
        align-items: center;
        margin-top: 32px;
    }
    .logoouter{
        height: 80px;
        width: 156px;
        border-radius: 8px;
    }
    .logoouter > img{
        height: 60px;
        width: 100%;
    }
    .heading > p{
        font-size: 18px;
        font-weight: 400;
        margin-top: 32px;
    }
    .outer{
        height: 203px;
    }
    .slider_outer{
        width: fit-content;
        display: flex;
        gap: 19px;
        box-sizing: border-box;
        transition: transform 0.3s ease;
    }
    
}

@media screen and (max-width: 400px){
    
    .heading > p{
        font-size: 5vw;
    }
    .carousel{
        height: 85px;
        width: 95%;  
    }
    .logoouter{
        height: 80px;
        width: 44vw;
    }
    .logoouter > img{
        height: 60px;
        width: 80%;
    }
    .outer{
        position: relative;
        height: 203px;
        width: 100%;
        background-color: #222222;
    }
    .heading > p{
        margin-top: 32px;
    }
    .carousel_out{
        margin-top: 32px;
        width: 90%;
    }
    .slider_outer{
        width: max-content;
        display: flex;
        gap: 16px;
        box-sizing: border-box;
        transition: transform 0.3s ease;
    }
}

@media (min-width: 600px) and (max-width: 900px){
    .npbtn{
        display: none;
    }
    .carousel_out{
        width: 90%;
    }
    .carousel{
        width: 90%;
    }
    .logoouter{
        height: 80px;
        width: 21.667vw;
        border-radius: 8px;
    }
    .logoouter > img{
        height: 60px;
        width: 100%;
        padding: 6px;
    }
}