.form1 {
    position: relative;
}

.form1::after {
    content: url("../../../public/images/Redesignpics/comp2.svg");
    position: absolute;
    bottom: -8px;
    right: 0;
}

.formstyle {
    scrollbar-width: 1px;
}






@media (min-width:993px) {

    .form1::before {
        content: url("../../../public/images/Redesignpics/comp1.svg");
        position: absolute;
        left: -50px;
    }
}