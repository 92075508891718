.right-panel{
    overflow-x: scroll;
    overflow-y: scroll;
    position: relative;
    display: flex;
    z-index: 0;
    width: 100%;
}
.zoom{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.zoom_in_out{
    display: flex;
    height: 60px;
    width: 30px;
    position: absolute;
    z-index: 1;
    background-color: rgba(88, 85, 85, 0.644);
    border-radius: 7px;
    justify-content: center;
    align-items: center;
    gap: 4px;
    color: white;
    top: 70vh;
    left: 93%;
}

.iconmation{
    font-size: 18px;
}

.close_pdf{
    position: absolute;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(88, 85, 85, 0.644);
    color: white;
    top: 15px;
    left: 5px;
    z-index: 1;
    opacity: .4;
}

.close_pdf:hover{
    opacity: 1;;
}