.poster_container{
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home_poster{
    display: flex;
    justify-content: center;
    height: fit-content;
    width: 100%;
}

.home_poster > img{
    width: 100%;
}

.mobile_poster{
    display: none;
}
.Tablet_poster{
    display: none;
}

.sm_mobile{
    display: none;
}
/* For mid range devices from 600px to 1000px*/
@media (min-width: 600px) and (max-width: 900px){
    .Tablet_poster{
        display: block;
        position: relative;
        height: fit-content;
        width: 100%;
    }

    .home_poster{
        display: none;
    }

    .sm_mobile{
        display: none;
    }

    .mobile_poster{
        display: none;
    }
}

/* For Mobile devices upto 600px*/
@media (min-width: 400px) and (max-width: 600px){
    .home_poster{
        display: none;
    }

    .mobile_poster{
        display: block;
        height: fit-content;
        width: 100%;
        object-fit: cover;
    }
    .Tablet_poster{
        display: none;
    }
    .sm_mobile{
        display: none;
    }
}

/* for mobile devices below 400px */
@media (max-width: 400px){
    .home_poster{
        display: none;
    }
    .mobile_poster{
        display: none;
    }
    .Tablet_poster{
        display: none;
    }
    .sm_mobile{
        display: block;
        height: fit-content;
        width: 100%;
    }
}