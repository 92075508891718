.html{
    font-size: 16px;
}
.advantageouter{
    height: fit-content;
    width: 100%;
    margin-bottom: 56px;
}
.content{
    width: 100%;
    display: flex;
    flex-direction: column;
}
.content > img{
    height: auto;
    width: 12.083vw;
    margin-top: 56px;
    margin-left: 29%;
}
.maincontent{
    display: flex;
    justify-content: space-evenly;
    margin-top: 64px;
}
.advantageimg{
    height: auto;
    width: 42.708vw;
    display: flex;
    align-items: center;
}

.advantagelist{
    display: flex;
    flex-direction: column;
}

.advpara{
    width: 44.444vw;
    font-family: Work Sans;
    line-height: 2rem;
    font-size: 1.25rem;
}
.list{
    height: fit-content;
    width: 44.444vw;
    margin-top: 40px;
    font-family: Work Sans;
}

.unique_list > li{
    font-size: 1.375rem;
    font-weight: 500;
}
.unique_list > p{
    font-size: 1.125rem;
    font-weight: 400;
    margin-left: 16px;
    line-height: 28px;
}
.list > ul > .unique_list >  li{
    list-style-image: url('../../../public/images/Redesignpics/listdot.svg');
    padding-left: 16px;
}
.list > ul > .unique_list:not(:first-child){
    margin-top: 16px;
}
.list > ul > .unique_list >  p{
    margin-top: 8px;
}

@media (max-width: 900px){
    .list{
        width: 100%;
        margin-top: 0;
        padding-left: 16px;
        text-align: justify;
    }
    .maincontent{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .advantageimg{
        width: 80%;
        margin: 32px 10%;
    }
    .unique_list>li{
        font-size: 1.25rem;
    }
    
}

@media(max-width: 600px){
    .maincontent{
        display: flex;
        justify-content: space-evenly;
        margin-top: 32px;
    }
    .unique_list > p{
        font-size: 1rem;
        margin-left: 12px;
    }
    .unique_list > li{
        font-size: 1.125rem;
    }
    .list{
        margin-top: 0px;
    }
    .list > ul > .unique_list >  li{
        list-style-image: url('../../../public/images/Redesignpics/listdot.svg');
        padding-left: 12px;
    }
}