.form_outer {
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
}

.contact_form {
  height: fit-content;
  width: 51.944vw;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
}

.contact_form > form {
  width: 100%;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* .input_divs:not(:first-child){
    margin-top: 32px;
} */

.input_divs {
  width: 25.417vw;
  height: 45px;
  display: flex;
  align-items: center;
  padding-left: 8px;
  border: 1px solid #95918f;
  border-radius: 4px;
}
.input_box {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  width: 100%;
}
.input_box:not(:first-child) {
  margin-top: 32px;
}

.input_divs > input {
  width: 25.417vw;
  border: none;
  gap: 16px;
}

.input_msg {
  width: 100%;
  height: 105px;
  padding-top: 0%;
  display: block;
}
.input_msg > textarea {
  max-height: 100px;
  min-height: 99px;
  width: 98%;
  word-break: break-all;
  padding-top: 12px;
}
.input_msg > textarea:focus {
  border: none;
  outline: none;
}

.input {
  width: 96%;
}
/* placeholder color */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #b9b2af;
  opacity: 1; /* Firefox */
  font-size: small;
  padding-top: 0px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #b9b2af;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #b9b2af;
}

input:focus {
  outline: none;
}

.form_checkbox {
  display: flex;
  width: 100%;
  margin-top: 40px;
  margin-left: 3px;
}
.form_checkbox > input[type="checkbox"] {
  height: 16px;
  width: 16px;
  margin: 3px;
  cursor: pointer;
  accent-color: black;
}

.form_checkbox > p {
  height: 66px;
  width: 45.833vw;
  color: #666666;
  font-family: Work Sans;
  font-size: 1rem;
  font-weight: 400;
  margin-left: 10px;
}
.btnbox {
  margin-top: 48px;
  margin-bottom: 60px;
  height: 48px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.submit_btn {
  height: 48px;
  width: 10.069vw;
  color: #222;
  background-color: #fff;
  border: 1px solid #222;
  border-radius: 4px;
  font-size: 1rem;
  font-weight: 600;
}
.submit_btn:hover {
  background-color: #222;
  color: #fff;
}
.more_outer {
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.more_outer > p {
  width: 28.333vw;
  font-size: 1.25rem;
  font-family: Work Sans;
  color: #222;
}
.veriline {
  width: 0.3px;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: 1px solid #666;
  margin-top: 80px;
}

@media screen and (max-width: 600px) {
  .contact_form {
    width: 100%;
  }

  .input_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
  }
  .input_divs,
  .input_divs > input {
    width: 91.111vw;
    border: none;
  }
  .input_divs {
    border: 1px solid #95918f;
  }
  .form_checkbox > p {
    width: 83.333vw;
  }
  .submit_btn {
    width: 34.444vw;
  }
  .more_outer {
    width: 100%;
    justify-content: flex-start;
    margin-top: 16px;
  }
  .more_outer > p {
    width: 100%;
    font-size: 1.125rem;
    color: #666;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .contact_form {
    width: 100%;
  }

  .input_box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: 100%;
  }
  .input_divs,
  .input_divs > input {
    width: 91.111vw;
    border: none;
  }
  .input_divs {
    border: 1px solid #95918f;
  }
  .form_checkbox > p {
    width: 83.333vw;
  }
  .submit_btn {
    width: 19.2vw;
  }
  .more_outer {
    width: 100%;
    justify-content: flex-start;
    margin-top: 16px;
  }
  .more_outer > p {
    width: 100%;
    font-size: 1.125rem;
    color: #666;
  }
}
