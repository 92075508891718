.App {
  position: fixed;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.delete-marker {
  color: red;
  position: absolute;
  background-color: rgba(255, 0, 0, 0.384);
}

.added-marker {
  background-color: rgba(0, 128, 0, 0.384);
  position: absolute;
  color: green;
}

.header {
  position: relative;
  height: fit-content;
  width: 100%;
  border-bottom: solid 2px gainsboro;
}

.eceEditor {
  position: relative;
  height: 100%;
  width: 100%;
  transition: all 1s ease-in-out;
}

.pdfViewer {
  overflow-x: scroll;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 0;
  flex: 1;
  overflow: hidden;
}

.line {
  height: 100%;
  width: 3px;
  cursor: col-resize;
  background-color: black;
  position: relative;
  top: 0;
  bottom: 0;
  z-index: 1;
}

.line.draggable {
  cursor: col-resize;
  background-color: blue;
}

/* .body {
  height: 93%;
  width: 100%;
  position: relative;
  display: flex;
  transition: all 2s ease-in-out;
} */
.container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
}

.RightBar {
  position: relative;
  top: 50px;
  right: 0;
  z-index: 10;
}

/* .container{
  width: 80%;
} */

.split_pane_left {
  flex: 2;
  overflow: hidden;
}

.resizer {
  border: 1px solid black;
  cursor: col-resize;
  user-select: none;
}

.draggingresizer {
  border: 1px solid #f26722;
  cursor: col-resize;
  user-select: none;
}

.leftpane {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.rightpane {
  flex: 1;
  overflow: scroll;
}

.split_pane {
  height: 100%;
  width: 100%;
  display: flex;
  position: static;
}