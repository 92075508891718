.viewer {
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
}
.viewer canvas {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  /* height: auto;
    width: auto; */
}
/* .pdf_container{
    height: 100vh;
    width: auto;
} */

.document {
  position: relative;
  margin-top: 48px;
  width: 500px;
}

.document_viewer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.twopageview {
  position: relative;
  display: grid;
  grid-template-columns: auto auto;
  gap: 5px;
  flex-direction: column;
}

.document_outer {
  position: relative;
  width: 100%;
  height: max-content;
  z-index: 0;
  display: flex;
  flex-direction: column;
}
.header {
  position: fixed;
  top: 82;
  width: -webkit-fill-available;
  width: -moz-available;
  width: stretch;
  z-index: 10;
}
